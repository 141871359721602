import React from "react"

import { Link } from "gatsby"

const ButtonLink = ({
  to = "/#",
  text = null,
  className = "",
  children = null,
}) => {
  // Check if link is external
  const isExternal = to && to.split("/")[0] !== ""

  return isExternal ? (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={`text-xl px-3 py-1  bg-fifth text-white hover:bg-white hover:text-fifth border-2 border-fifth  font-semibold   transition duration-200 ease-in-out transform hover:scale-105 ${className}`}
      title={text}
    >
      {text}
    </a>
  ) : (
    <Link
      className={` text-xl px-3 py-1  bg-fifth  text-white hover:bg-white hover:text-fifth border-2 border-fifth  font-semibold ${className} transition duration-200 ease-in-out transform hover:scale-105`}
      to={to}
      title={text}
    >
      {text}
      {children}
    </Link>
  )
}

export default ButtonLink
