import React from "react"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import ButtonLink from "../components/ButtonLink"
import BackgroundContainer from "../components/containers/BackgroundContainer"

const SuccessPage = () => (
  <Layout>
    <SEO title="Success" />
    <BackgroundContainer>
      <div className="grid justify-items-center my-20 text-center mx-2">
        <h1 className="mb-8">Success!</h1>
        <h2>Your message has been sent.</h2>

        <ButtonLink className="mt-20 uppercase tracking-wide">
          Home Page
        </ButtonLink>
      </div>
    </BackgroundContainer>
  </Layout>
)

export default SuccessPage
